import { default as _91id_93EMAjiuauF6Meta } from "/workspace/pages/[id].vue?macro=true";
import { default as about_45usACA9cvtJITMeta } from "/workspace/pages/about-us.vue?macro=true";
import { default as accessibilitybFDBjhyroGMeta } from "/workspace/pages/accessibility.vue?macro=true";
import { default as commercial_45and_45leasingNeuRr5Ui4IMeta } from "/workspace/pages/commercial-and-leasing.vue?macro=true";
import { default as contact_45uszAjl9BMeGEMeta } from "/workspace/pages/contact-us.vue?macro=true";
import { default as dine_45drinkwCHdeANW57Meta } from "/workspace/pages/dine-drink.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as _91id_93z7dKbZaNF8Meta } from "/workspace/pages/jobs/[id].vue?macro=true";
import { default as indexgb2iIOVW1vMeta } from "/workspace/pages/jobs/index.vue?macro=true";
import { default as kids_45club_45sign_45upuW41za6pW2Meta } from "/workspace/pages/kids-club-sign-up.vue?macro=true";
import { default as kidsC9gyJUA18MMeta } from "/workspace/pages/kids.vue?macro=true";
import { default as _91id_93q8ndfJj9MyMeta } from "/workspace/pages/news-and-events/[id].vue?macro=true";
import { default as indexWaC6NUJmlmMeta } from "/workspace/pages/news-and-events/index.vue?macro=true";
import { default as _91id_93zg6aURWL8jMeta } from "/workspace/pages/offers/[id].vue?macro=true";
import { default as indext302HFzB8rMeta } from "/workspace/pages/offers/index.vue?macro=true";
import { default as privacy_45policyJuUuuPjb3dMeta } from "/workspace/pages/privacy-policy.vue?macro=true";
import { default as servicesA4efoA1KA6Meta } from "/workspace/pages/services.vue?macro=true";
import { default as sitemapaBXUHH5Q3gMeta } from "/workspace/pages/sitemap.vue?macro=true";
import { default as _91id_93PrvRVf4QidMeta } from "/workspace/pages/stores/[id].vue?macro=true";
import { default as indexXB0IUWVY76Meta } from "/workspace/pages/stores/index.vue?macro=true";
import { default as studentskuae5ir5EYMeta } from "/workspace/pages/students.vue?macro=true";
import { default as terms_45and_45conditionsD3pGvh9rLRMeta } from "/workspace/pages/terms-and-conditions.vue?macro=true";
import { default as the_45collectiveI8iV7WKX3mMeta } from "/workspace/pages/the-collective.vue?macro=true";
import { default as by_45caraxNFjAtB0hMeta } from "/workspace/pages/visit/by-car.vue?macro=true";
import { default as indexzf5XOJsm66Meta } from "/workspace/pages/visit/index.vue?macro=true";
import { default as _91id_93EqfW9PXabFMeta } from "/workspace/pages/we-care/[id].vue?macro=true";
export default [
  {
    name: _91id_93EMAjiuauF6Meta?.name ?? "id",
    path: _91id_93EMAjiuauF6Meta?.path ?? "/:id()",
    meta: _91id_93EMAjiuauF6Meta || {},
    alias: _91id_93EMAjiuauF6Meta?.alias || [],
    redirect: _91id_93EMAjiuauF6Meta?.redirect,
    component: () => import("/workspace/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: about_45usACA9cvtJITMeta?.name ?? "about-us",
    path: about_45usACA9cvtJITMeta?.path ?? "/about-us",
    meta: about_45usACA9cvtJITMeta || {},
    alias: about_45usACA9cvtJITMeta?.alias || [],
    redirect: about_45usACA9cvtJITMeta?.redirect,
    component: () => import("/workspace/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: accessibilitybFDBjhyroGMeta?.name ?? "accessibility",
    path: accessibilitybFDBjhyroGMeta?.path ?? "/accessibility",
    meta: accessibilitybFDBjhyroGMeta || {},
    alias: accessibilitybFDBjhyroGMeta?.alias || [],
    redirect: accessibilitybFDBjhyroGMeta?.redirect,
    component: () => import("/workspace/pages/accessibility.vue").then(m => m.default || m)
  },
  {
    name: commercial_45and_45leasingNeuRr5Ui4IMeta?.name ?? "commercial-and-leasing",
    path: commercial_45and_45leasingNeuRr5Ui4IMeta?.path ?? "/commercial-and-leasing",
    meta: commercial_45and_45leasingNeuRr5Ui4IMeta || {},
    alias: commercial_45and_45leasingNeuRr5Ui4IMeta?.alias || [],
    redirect: commercial_45and_45leasingNeuRr5Ui4IMeta?.redirect,
    component: () => import("/workspace/pages/commercial-and-leasing.vue").then(m => m.default || m)
  },
  {
    name: contact_45uszAjl9BMeGEMeta?.name ?? "contact-us",
    path: contact_45uszAjl9BMeGEMeta?.path ?? "/contact-us",
    meta: contact_45uszAjl9BMeGEMeta || {},
    alias: contact_45uszAjl9BMeGEMeta?.alias || [],
    redirect: contact_45uszAjl9BMeGEMeta?.redirect,
    component: () => import("/workspace/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: dine_45drinkwCHdeANW57Meta?.name ?? "dine-drink",
    path: dine_45drinkwCHdeANW57Meta?.path ?? "/dine-drink",
    meta: dine_45drinkwCHdeANW57Meta || {},
    alias: dine_45drinkwCHdeANW57Meta?.alias || [],
    redirect: dine_45drinkwCHdeANW57Meta?.redirect,
    component: () => import("/workspace/pages/dine-drink.vue").then(m => m.default || m)
  },
  {
    name: index9S03gOyIVcMeta?.name ?? "index",
    path: index9S03gOyIVcMeta?.path ?? "/",
    meta: index9S03gOyIVcMeta || {},
    alias: index9S03gOyIVcMeta?.alias || [],
    redirect: index9S03gOyIVcMeta?.redirect,
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93z7dKbZaNF8Meta?.name ?? "jobs-id",
    path: _91id_93z7dKbZaNF8Meta?.path ?? "/jobs/:id()",
    meta: _91id_93z7dKbZaNF8Meta || {},
    alias: _91id_93z7dKbZaNF8Meta?.alias || [],
    redirect: _91id_93z7dKbZaNF8Meta?.redirect,
    component: () => import("/workspace/pages/jobs/[id].vue").then(m => m.default || m)
  },
  {
    name: indexgb2iIOVW1vMeta?.name ?? "jobs",
    path: indexgb2iIOVW1vMeta?.path ?? "/jobs",
    meta: indexgb2iIOVW1vMeta || {},
    alias: indexgb2iIOVW1vMeta?.alias || [],
    redirect: indexgb2iIOVW1vMeta?.redirect,
    component: () => import("/workspace/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: kids_45club_45sign_45upuW41za6pW2Meta?.name ?? "kids-club-sign-up",
    path: kids_45club_45sign_45upuW41za6pW2Meta?.path ?? "/kids-club-sign-up",
    meta: kids_45club_45sign_45upuW41za6pW2Meta || {},
    alias: kids_45club_45sign_45upuW41za6pW2Meta?.alias || [],
    redirect: kids_45club_45sign_45upuW41za6pW2Meta?.redirect,
    component: () => import("/workspace/pages/kids-club-sign-up.vue").then(m => m.default || m)
  },
  {
    name: kidsC9gyJUA18MMeta?.name ?? "kids",
    path: kidsC9gyJUA18MMeta?.path ?? "/kids",
    meta: kidsC9gyJUA18MMeta || {},
    alias: kidsC9gyJUA18MMeta?.alias || [],
    redirect: kidsC9gyJUA18MMeta?.redirect,
    component: () => import("/workspace/pages/kids.vue").then(m => m.default || m)
  },
  {
    name: _91id_93q8ndfJj9MyMeta?.name ?? "news-and-events-id",
    path: _91id_93q8ndfJj9MyMeta?.path ?? "/news-and-events/:id()",
    meta: _91id_93q8ndfJj9MyMeta || {},
    alias: _91id_93q8ndfJj9MyMeta?.alias || [],
    redirect: _91id_93q8ndfJj9MyMeta?.redirect,
    component: () => import("/workspace/pages/news-and-events/[id].vue").then(m => m.default || m)
  },
  {
    name: indexWaC6NUJmlmMeta?.name ?? "news-and-events",
    path: indexWaC6NUJmlmMeta?.path ?? "/news-and-events",
    meta: indexWaC6NUJmlmMeta || {},
    alias: indexWaC6NUJmlmMeta?.alias || [],
    redirect: indexWaC6NUJmlmMeta?.redirect,
    component: () => import("/workspace/pages/news-and-events/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93zg6aURWL8jMeta?.name ?? "offers-id",
    path: _91id_93zg6aURWL8jMeta?.path ?? "/offers/:id()",
    meta: _91id_93zg6aURWL8jMeta || {},
    alias: _91id_93zg6aURWL8jMeta?.alias || [],
    redirect: _91id_93zg6aURWL8jMeta?.redirect,
    component: () => import("/workspace/pages/offers/[id].vue").then(m => m.default || m)
  },
  {
    name: indext302HFzB8rMeta?.name ?? "offers",
    path: indext302HFzB8rMeta?.path ?? "/offers",
    meta: indext302HFzB8rMeta || {},
    alias: indext302HFzB8rMeta?.alias || [],
    redirect: indext302HFzB8rMeta?.redirect,
    component: () => import("/workspace/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyJuUuuPjb3dMeta?.name ?? "privacy-policy",
    path: privacy_45policyJuUuuPjb3dMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyJuUuuPjb3dMeta || {},
    alias: privacy_45policyJuUuuPjb3dMeta?.alias || [],
    redirect: privacy_45policyJuUuuPjb3dMeta?.redirect,
    component: () => import("/workspace/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: servicesA4efoA1KA6Meta?.name ?? "services",
    path: servicesA4efoA1KA6Meta?.path ?? "/services",
    meta: servicesA4efoA1KA6Meta || {},
    alias: servicesA4efoA1KA6Meta?.alias || [],
    redirect: servicesA4efoA1KA6Meta?.redirect,
    component: () => import("/workspace/pages/services.vue").then(m => m.default || m)
  },
  {
    name: sitemapaBXUHH5Q3gMeta?.name ?? "sitemap",
    path: sitemapaBXUHH5Q3gMeta?.path ?? "/sitemap",
    meta: sitemapaBXUHH5Q3gMeta || {},
    alias: sitemapaBXUHH5Q3gMeta?.alias || [],
    redirect: sitemapaBXUHH5Q3gMeta?.redirect,
    component: () => import("/workspace/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: _91id_93PrvRVf4QidMeta?.name ?? "stores-id",
    path: _91id_93PrvRVf4QidMeta?.path ?? "/stores/:id()",
    meta: _91id_93PrvRVf4QidMeta || {},
    alias: _91id_93PrvRVf4QidMeta?.alias || [],
    redirect: _91id_93PrvRVf4QidMeta?.redirect,
    component: () => import("/workspace/pages/stores/[id].vue").then(m => m.default || m)
  },
  {
    name: indexXB0IUWVY76Meta?.name ?? "stores",
    path: indexXB0IUWVY76Meta?.path ?? "/stores",
    meta: indexXB0IUWVY76Meta || {},
    alias: indexXB0IUWVY76Meta?.alias || [],
    redirect: indexXB0IUWVY76Meta?.redirect,
    component: () => import("/workspace/pages/stores/index.vue").then(m => m.default || m)
  },
  {
    name: studentskuae5ir5EYMeta?.name ?? "students",
    path: studentskuae5ir5EYMeta?.path ?? "/students",
    meta: studentskuae5ir5EYMeta || {},
    alias: studentskuae5ir5EYMeta?.alias || [],
    redirect: studentskuae5ir5EYMeta?.redirect,
    component: () => import("/workspace/pages/students.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsD3pGvh9rLRMeta?.name ?? "terms-and-conditions",
    path: terms_45and_45conditionsD3pGvh9rLRMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditionsD3pGvh9rLRMeta || {},
    alias: terms_45and_45conditionsD3pGvh9rLRMeta?.alias || [],
    redirect: terms_45and_45conditionsD3pGvh9rLRMeta?.redirect,
    component: () => import("/workspace/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: the_45collectiveI8iV7WKX3mMeta?.name ?? "the-collective",
    path: the_45collectiveI8iV7WKX3mMeta?.path ?? "/the-collective",
    meta: the_45collectiveI8iV7WKX3mMeta || {},
    alias: the_45collectiveI8iV7WKX3mMeta?.alias || [],
    redirect: the_45collectiveI8iV7WKX3mMeta?.redirect,
    component: () => import("/workspace/pages/the-collective.vue").then(m => m.default || m)
  },
  {
    name: by_45caraxNFjAtB0hMeta?.name ?? "visit-by-car",
    path: by_45caraxNFjAtB0hMeta?.path ?? "/visit/by-car",
    meta: by_45caraxNFjAtB0hMeta || {},
    alias: by_45caraxNFjAtB0hMeta?.alias || [],
    redirect: by_45caraxNFjAtB0hMeta?.redirect,
    component: () => import("/workspace/pages/visit/by-car.vue").then(m => m.default || m)
  },
  {
    name: indexzf5XOJsm66Meta?.name ?? "visit",
    path: indexzf5XOJsm66Meta?.path ?? "/visit",
    meta: indexzf5XOJsm66Meta || {},
    alias: indexzf5XOJsm66Meta?.alias || [],
    redirect: indexzf5XOJsm66Meta?.redirect,
    component: () => import("/workspace/pages/visit/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93EqfW9PXabFMeta?.name ?? "we-care-id",
    path: _91id_93EqfW9PXabFMeta?.path ?? "/we-care/:id()",
    meta: _91id_93EqfW9PXabFMeta || {},
    alias: _91id_93EqfW9PXabFMeta?.alias || [],
    redirect: _91id_93EqfW9PXabFMeta?.redirect,
    component: () => import("/workspace/pages/we-care/[id].vue").then(m => m.default || m)
  }
]